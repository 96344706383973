// 正则特殊字符
const regTxt = new RegExp(/["'\\?^？|￥$^@!~`>》《<*；;]+/);

const MustInput = {
  required: true,
  asyncValidator: (rule, value) => new Promise((resolve, reject) => {
    if (value === '') {
      reject(new Error('必填项'));
    } else {
      resolve(value);
    }
  }),
  trigger: ['blur', 'change'],

};

// 验证特殊字符串
const DrawDetails = {
  required: true,
  asyncValidator: (rule, value) => new Promise((resolve, reject) => {
    // 禁止输入'|'以及空格
    if (value === undefined) {
      value = '';
    }
    if (value === '') {
      reject(new Error('必填项'));
    } else if (/\s{2,}/.test(value)) {
      reject(new Error('请勿输入多个空格字符串'));
    } else if (regTxt.test(value)) {
      reject(new Error('请务输入特殊符号'));
    } else {
      resolve(value);
    }
  }),
  trigger: ['blur', 'change'],
};

// 手机号码 验证器
const MobileRules = {
  required: true,
  asyncValidator: (rule, value) => new Promise((resolve, reject) => {
    if (value === '') {
      reject(new Error('必填内容'));
    } else if (value.length !== 11) {
      reject(new Error('请正确填写中国大陆地区手机号'));
    } else if (!/^[1][3458]+[0-9]{9}$/.test(value)) {
      reject(new Error('请正确填写中国大陆地区手机号'));
    } else {
      resolve(value);
    }
  }),
  trigger: ['blur', 'change'],
};

// 密码验证器
const PassWordRules = {
  required: true,
  asyncValidator: (rule, value) => new Promise((resolve, reject) => {
    if (value === '') {
      reject(new Error('必填内容'));
    } else if (/[^A-Za-z0-9_&@.]+/.test(value)) {
      reject(new Error('请勿输入特殊字符'));
    } else if (!/^[\w&@.]{6,18}$/.test(value)) {
      reject(new Error('密码长度为6~18位组成'));
    } else if (/([\w&@.])\1{5}/.test(value)) {
      reject(new Error('请勿输入相同字符'));
    } else {
      resolve(value);
    }
  }),
  trigger: ['blur', 'change'],
};

// 验证码校验器
const VerifyCodeRules = {
  required: true,
  asyncValidator: (rule, value) => new Promise((resolve, reject) => {
    if (value === '') {
      reject(new Error('必填内容'));
    } else if (value.length !== 4) {
      reject(new Error('长度为4位'));
    } else {
      resolve(value);
    }
  }),
  trigger: ['blur', 'change'],
};

// 手机验证码 校验器
const MobileVerifyRules = {
  required: true,
  asyncValidator: (rule, value) => new Promise((resolve, reject) => {
    if (value === '') {
      reject(new Error('必填内容'));
    } else if (value.length !== 4) {
      reject(new Error('长度为4位'));
    } else {
      resolve(value);
    }
  }),
  trigger: ['blur', 'change'],
};

// 用户名称
const UserRules = {
  required: false,
  asyncValidator: (rule, value) => new Promise((resolve, reject) => {
    if (value === '') {
      reject(new Error('必填项'));
    } else if (value.length < 4 || value.length > 11) {
      reject(new Error('请控制内容长度在2-11字符区间'));
    } else {
      resolve(value);
    }
  }),
  trigger: ['blur'],
};

// 联系人
const AttnRules = {
  required: false,
  asyncValidator: (rule, value) => new Promise((resolve, reject) => {
    if (value === '') {
      reject(new Error('必填项'));
    } else if (value.length > 10) {
      reject(new Error('超出内容长度范围值'));
    } else {
      resolve(value);
    }
  }),
  trigger: ['blur', 'change'],
};

// 公司名称
const CompanyRules = {
  required: true,
  asyncValidator: (rule, value) => new Promise((resolve, reject) => {
    if (value === '') {
      reject(new Error('必填项'));
    } else if (value !== '' && value.length < 2) {
      reject(new Error('输入内容不得低于2位'));
    } else if (value !== '' && value.length > 120) {
      reject(new Error('超出长度范围值'));
    } else if (regTxt.test(value)) {
      reject(new Error('请务输入特殊符号'));
    } else {
      resolve(value);
    }
  }),
  trigger: ['blur', 'change'],
};

// 公司名称(必须为中文)
const CompanyCNRules = {
  required: true,
  asyncValidator: (rule, value) => new Promise((resolve, reject) => {
    if (value === '') {
      reject(new Error('必填项'));
    } else if (/[^\u4e00-\u9fa5()（）]+/.test(value)) {
      // 内容为汉字
      reject(new Error('内容限定为汉字'));
    } else if (value !== '' && value.length < 2) {
      reject(new Error('输入内容不得低于2位'));
    } else if (value !== '' && value.length > 120) {
      reject(new Error('超出长度范围值'));
    } else if (regTxt.test(value)) {
      reject(new Error('请务输入特殊符号'));
    } else {
      resolve(value);
    }
  }),
  trigger: ['blur', 'change'],
};

// 地址
const AddressRules = {
  required: false,
  asyncValidator: (rule, value) => new Promise((resolve, reject) => {
    if (value === '') {
      reject(new Error('必填项'));
    } else if (value.length > 150) {
      reject(new Error('超出内容长度范围值'));
    } else {
      resolve(value);
    }
  }),
  trigger: ['blur'],
};

// 查询海关编码
const HscodeKeyWord = {
  required: true,
  asyncValidator: (rule, value) => new Promise((resolve, reject) => {
    if (value === '') {
      reject(new Error('输入查询内容'));
    } else if (value !== '' && /^[0-9]/.test(value) && value.length < 4) {
      reject(new Error('海关编码最少4位'));
    } else if (value !== '' && value.length > 30) {
      reject(new Error('超出长度范围值'));
    } else if (regTxt.test(value)) {
      reject(new Error('请务输入特殊符号'));
    } else {
      resolve(value);
    }
  }),
  trigger: ['blur'],
};

// 验证不为空
const VerifyEmptyRules = {
  required: true,
  asyncValidator: (rule, value) => new Promise((resolve, reject) => {
    if (value === '') {
      reject(new Error('必填项'));
    } else {
      resolve(value);
    }
  }),
  trigger: ['blur', 'change'],
};

// 合同，箱单，发票号正则
const VerifyTradInfo = {
  required: true,
  asyncValidator: (rule, value) => new Promise((resolve, reject) => {
    if (value === '') {
      reject(new Error('必填项'));
    } else if (value.length < 3) {
      reject(new Error('请输入的内容不足3个字符'));
    } else if (value.length > 15) {
      reject(new Error('内容超出15个字符'));
    } else if (!/^[\w-]{3,15}$/.test(value)) {
      // 合同号大于3个字符小于15个字符，
      reject(new Error('请输入英文、正确的字符串'));
    } else {
      resolve(value);
    }
  }),
  trigger: ['blur', 'change'],
};

// 单位信用代码
const CompanyCode = {
  required: true,
  asyncValidator: (rule, value) => new Promise((resolve, reject) => {
    if (value === '') {
      reject(new Error('必填项'));
    } else if (!/^\w{18}$/.test(value)) {
      reject(new Error('请输入正确的18位信用编码'));
    } else {
      resolve(value);
    }
  }),
  trigger: ['blur', 'change'],
};

// 海关注册码
const CustomsID = {
  required: true,
  asyncValidator: (rule, value) => new Promise((resolve, reject) => {
    if (value === '') {
      reject(new Error('必填项'));
    } else if (!/^[0-9A-Za-z]{10}$/.test(value)) {
      reject(new Error('请输入正确的10位海关注册编码'));
    } else {
      resolve(value);
    }
  }),
  trigger: ['blur', 'change'],
};

const FactoryMobile = (rule, value, callback) => {
  if (value.length !== 11) {
    callback(new Error('请正确填写中国大陆地区手机号'));
  } else if (!/^[1][3458]+[0-9]{9}$/.test(value)) {
    callback(new Error('请正确填写中国大陆地区手机号'));
  } else {
    callback();
  }
};
export default {
  MustInput,
  DrawDetails,
  MobileRules,
  PassWordRules,
  VerifyCodeRules,
  MobileVerifyRules,
  UserRules,
  AttnRules,
  CompanyRules,
  CompanyCNRules,
  AddressRules,
  HscodeKeyWord,
  VerifyTradInfo,
  CompanyCode,
  VerifyEmptyRules,
  CustomsID,
  FactoryMobile,
};
