import {
  ElMessage,
} from 'element-plus';
import md5 from 'blueimp-md5';
import myAxios from '../../../utils/axios/index_v2';
// import MenuListClass from '../../../store/class/menuListClass';
// UsersecurityClass 账户安全类
export default class UsersecurityClass {
  constructor(modelTypeName, nodeRef, recoverFormDatas, rectvDatas) {
    this.caller = 'RECR';// 调用者 指找回密码整个流程
    this.orderByItem = ['RecoverMB', 'RecoverVfyCode', 'RecoverRestPwd'];// 依次展示顺序
    this.modelTypeName = modelTypeName;// 当前状态
    this.nodeRef = nodeRef;// 各ref节点
    this.mbDatas = recoverFormDatas;// 输入账号表单数据
    this.rectvDatas = rectvDatas;// 各表单所需要的响应数据
    // this.setInterval = this.rectvDatas.setInterval;// 保存倒计时
  }

  // setintervalFunc 倒计时
  setintervalFunc = (s) => {
    if (this.rectvDatas.interVal === '获取验证码'
      && this.rectvDatas.setInterval === null) {
      this.rectvDatas.interVal = `重新获取还需${s}秒`;
      this.rectvDatas.setInterval = setInterval(() => {
        s -= 1;
        this.rectvDatas.interVal = `重新获取还需${s}秒`;
        if (s < 1 || this.rectvDatas.setInterval === null) {
          clearInterval(this.rectvDatas.setInterval);
          this.rectvDatas.setInterval = null;
          this.rectvDatas.interVal = '获取验证码';
        }
      }, 1000);
    }
  }

  // verifyFormDatas 校验表单正确性
  // @returns true:正确 false:有误
  verifyFormDatas = async () => {
    // 以下为除获取验证码之外的校验
    // 这里使用await，validate是异步函数
    let b = true;
    await this.nodeRef.validate((err) => {
      b = err;
    });

    // 已查过号码为错误信息
    if (
      this.modelTypeName === 'RecoverMB'
      && this.mbDatas.mobile !== ''
      && this.mbDatas.mobile === this.rectvDatas.savePrevMobile.mobile
    ) {
      ElMessage({
        type: 'error',
        message: this.rectvDatas.savePrevMobile.errmsg.message,
        duration: 3000,
      });
      // const errmsg = this.rectvDatas.savePrevMobile.errmsg.message;
      // this.rectvDatas.errRecoverMB = (this.rectvDatas.errRecoverMB === errmsg) ? `${errmsg}  ` : errmsg;
      return false;
    }

    // 获取手机验证码
    if (this.modelTypeName === 'RecoverVfy') { // 获取验证码不需要校验输入框信息
      // 判断是否为倒计时
      if (this.rectvDatas.interVal !== '获取验证码') {
        this.rectvDatas.errRecoverVfyCode = '请勿重复获取验证码';
        return false;
      }
      return true;
    }

    // 校验验证码是否正确（下一步）
    // 未处于获取状态时，点击'下一步'验证验证码无效
    if (this.modelTypeName === 'RecoverVfyCode') {
      // 提交与验证过的相同数据返回上次服务器查询的错误信息
      const pervInputVal = this.rectvDatas.savePrevVerifyCode.verifyCode;
      if (pervInputVal !== '' && this.mbDatas.verifyCode === pervInputVal) { // 错误验证码
        this.rectvDatas.errRecoverVfyCode = this.rectvDatas.savePrevVerifyCode.errmsg.message;
        return false;// 错误返回，让其定位错误
      }
      if (this.rectvDatas.interVal === '获取验证码') {
        this.rectvDatas.errRecoverVfyCode = '请先获取手机验证码';
        return false;
      }
    }

    return b;
  }

  /**
   * focusToErrInput 定位到错误的输入框
   * @param {int} num 参数为选择元素编号
   */
  focusToErrInput = (num) => {
    const nodeEl = this.nodeRef.$el[num];// input节点
    nodeEl.focus();// 获得焦点
    nodeEl.select();// 全选
  }

  // checkMobileIsExists 向服务器校验手机号是否存在
  // @parms{class} menuListClass 实例化MenuListClass类
  checkMobileIsExists = (menuListClass) => {
    myAxios().initAxios({
      method: 'POST',
      url: '/recover_password',
      Headers: { Accept: 'application/json; charset=utf-8' },
      data: {
        caller: this.caller, // 调用者
        model_type: this.modelTypeName, // 调用状态
        mobile: this.mbDatas.mobile, // 手机号
        verify_code: this.mbDatas.verifyCode, // 验证码
        password: md5(this.mbDatas.passWord), // 密码
      },
    }).then((res) => {
      // 200 成功
      // console.log(res);
      try {
        if (res.currentURI === 'RecoverVfy') {
          this.setintervalFunc(res.limitTime);
        }

        if (this.orderByItem.includes(this.modelTypeName)) {
          const idx = this.orderByItem.indexOf(this.modelTypeName);
          if (idx === this.orderByItem.length - 1) {
            // 最后一个值完成按钮,
            // 选tabs最后一个按钮内容展示

            menuListClass.closeCurrentPageOpenLogin();
          } else { // 展示下一个窗体
            this.rectvDatas.showForm = this.orderByItem[idx + 1];
          }
        }
      } catch (error) {
        console.error(error);
      }
    }).catch((err) => {
      // console.log(err);

      // 400:未检测手机，200：新创建，5001，重复操作  4001，内部错误

      this.focusToErrInput(0);// 定位至错误输入框

      // 获取验证码
      if (err.currentURI === 'RecoverVfy' && err.code === 5001) { // 申请过的未过期
        this.setintervalFunc(err.limitTime);
      } else if (
        ['RecoverVfy', 'RecoverVfyCode', 'RecoverRestPwd'].includes(err.currentURI) && ([400, 4001].includes(err.code))) {
        // 无效请求（检测手机非法操作）或内部错误，处理方法，回到第一步
        this.rectvDatas.showForm = 'RecoverMB';// 显示验证手机
      }
      // 提交找回手机号错误信息提示:存入临时rectvDatas.savePrevMobile中
      // 防止用户不断提交相同数据,直接返回数据，不再向服务器查询
      if (err.currentURI === 'RecoverMB' && err.code === 4000) {
        Object.assign(this.rectvDatas.savePrevMobile, {
          mobile: this.mbDatas.mobile, // 存取上次错误值
          errmsg: err,
        });
        this.rectvDatas.errRecoverMB = err.message;
      }
      // 校验验证码 400(参数未传值),4001(内部错误），4004（验证失败),200(成功）
      if (err.currentURI === 'RecoverVfyCode' && err.code === 4004) {
        // 将错误信息存入错误信息临时保存
        // 防止用户不断提交相同数据,直接返回数据，不再向服务器查询
        Object.assign(this.rectvDatas.savePrevVerifyCode, {
          verifyCode: this.mbDatas.verifyCode, // 存取上次错误值
          errmsg: err,
        });
        this.rectvDatas.errRecoverVfyCode = err.message;
      }
      // 修改密码
      if (err.currentURI === 'RecoverRestPwd' && err.code === 3001) {
        this.rectvDatas.errRecoverRestPwd = err.message;
      }
      ElMessage({
        type: 'error',
        message: err.message,
        duration: 3000,
      });
    });
  }

  /**
   * initFormDatas 初始化Usercirity.vue中数据
   * @param {object} recoverFormDatas recoverFormDatas内容
   * @param {object} rectvDatas  rectvDatas内容
   * @param {object} nodeRefs  nodeRefs内容(3个ref节点)
   */
  static initFormDatas = (recoverFormDatas, rectvDatas, nodeRefs) => {
    // 清除定时器
    if (rectvDatas.setInterval !== null) {
      clearInterval(rectvDatas.setInterval);
    }

    // 初始化表单数据，置空
    Object.assign(recoverFormDatas, {
      mobile: '', // 要找回的手机号
      verifyCode: '', // 手机验证码
      passWord: '', // 更新的密码
    });

    // 初始化 rectvDatas响应数据
    Object.assign(rectvDatas, {
      showForm: 'RecoverMB', // 当前展示表单内容
      setInterval: null,
      savePrevMobile: { mobile: '', errmsg: '' }, // 保存上次查询过的错误电话号码
      savePrevVerifyCode: { verifyCode: '', errmsg: '' }, // 保存上次查询过的验证码
      errRecoverMB: '', // 手机号-错误提示信息
      errRecoverVfyCode: '', // 验证码-错误提示信息
      errRecoverRestPwd: '', // 密码-错误提示信息
      interVal: '获取验证码', // 记录获取验证码倒计时
    });

    // 清除所验证信息
    Object.values(nodeRefs).forEach((refNode) => {
      refNode.value.clearValidate();
    });
  }
}
