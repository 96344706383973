<template>
  <div class="usersecurity">
    <div class="mycardbox">
      <div class="mobileForm" v-show="rectvDatas.showForm===modelType.Mb">
        <my-card>
          <template #mycard>
            <h1>输入账号</h1>
          </template>
        </my-card>
        <my-card>
          <template #mycard>
            <div class="contentBox">
              <div class="box">
                <el-form
                  ref="srcymbRef"
                  :model="recoverFormDatas"
                  :rules="srcyMbRules"
                  class="srcymb"
                  @submit.prevent
                >
                  <!--
                  @submit.prevent-->
                  <p>请输入要找回密码的手机号码</p>
                  <el-form-item label-width="0px" prop="mobile" :error="rectvDatas.errRecoverMB">
                    <el-input
                      placeholder="请输入手机号"
                      type="text"
                      v-model="recoverFormDatas.mobile"
                      clearable
                    >
                      <template #prefix>
                        <span>+86</span>
                      </template>
                    </el-input>
                  </el-form-item>
                  <el-form-item>
                    <el-button
                      type="primary"
                      native-type="submit"
                      @click="formSubmit(modelType.Mb)"
                    >下一步</el-button>
                  </el-form-item>
                </el-form>
              </div>
            </div>
          </template>
        </my-card>
      </div>
      <div class="verifyForm" v-show="rectvDatas.showForm===modelType.Code">
        <my-card>
          <template #mycard>
            <h1>安全验证</h1>
          </template>
        </my-card>
        <my-card>
          <template #mycard>
            <div class="contentBox">
              <div class="box">
                <el-form
                  ref="srcyVfyRef"
                  :model="recoverFormDatas"
                  :rules="srcyVfyRules"
                  class="srcymb"
                  @submit.prevent
                >
                  <p>
                    您正在对账号：
                    <el-link type="primary">{{recoverFormDatas.mobile}}</el-link>进行找回密码操作，请核对账号无误后，进行如下验证工作：
                  </p>
                  <p>点击获取验证码，本机{{recoverFormDatas.mobile}}会收到安全验证短信</p>
                  <el-form-item
                    label-width="0px"
                    prop="verifyCode"
                    :error="rectvDatas.errRecoverVfyCode"
                  >
                    <div class="verificationCode">
                      <el-input
                        placeholder="验证码"
                        type="text"
                        v-model="recoverFormDatas.verifyCode"
                        clearable
                      />
                      <div class="vrfCode">
                        <div class="vrfCodeCtn" @click="formSubmit(modelType.Vfy)">
                          <el-image style="width: 100px; height: 100px" fit="cover" src>
                            <template #error>
                              <div class="image-slot">{{rectvDatas.interVal}}</div>
                            </template>
                          </el-image>
                        </div>
                      </div>
                    </div>
                  </el-form-item>
                  <el-form-item>
                    <el-button
                      type="primary"
                      native-type="submit"
                      @click="formSubmit(modelType.Code)"
                    >下一步</el-button>
                  </el-form-item>
                </el-form>
              </div>
            </div>
          </template>
        </my-card>
      </div>
      <div class="pwdForm" v-show="rectvDatas.showForm===modelType.Pwd">
        <my-card>
          <template #mycard>
            <h1>重置密码</h1>
          </template>
        </my-card>
        <my-card>
          <template #mycard>
            <div class="contentBox">
              <div class="box">
                <el-form
                  ref="srcyPwdRef"
                  :model="recoverFormDatas"
                  :rules="srcyPwdRules"
                  class="srcymb"
                  @submit.prevent
                >
                  <p>
                    重置当前账号
                    <el-link type="primary">{{recoverFormDatas.mobile}}</el-link>密码，请妥善保管！
                  </p>
                  <el-form-item
                    label-width="0px"
                    prop="passWord"
                    :error="rectvDatas.errRecoverRestPwd"
                  >
                    <el-input
                      placeholder="6~18位,字母、数字组成"
                      type="passWord"
                      v-model="recoverFormDatas.passWord"
                      :show-password="true"
                      clearable
                    ></el-input>
                  </el-form-item>
                  <el-form-item>
                    <el-button
                      type="primary"
                      native-type="submit"
                      @click="formSubmit(modelType.Pwd)"
                    >完成</el-button>
                  </el-form-item>
                </el-form>
              </div>
            </div>
          </template>
        </my-card>
      </div>
    </div>
  </div>
</template>

<script>
import {
  ref,
  reactive,
  toRefs,
  inject,
  // onMounted,
} from 'vue';
import { useRouter } from 'vue-router';
import MyCard from '../components/util/MyCard.vue';
import Rules from '../utils/utilsClass/rulesClass';
import UsersecurityClass from '../components/Usersecurity/class/usersecurityClass';
import MenuListClass from '../store/class/menuListClass';

export default {
  name: 'usersecurity',
  components: { MyCard },
  setup() {
    /** **输入账号*** */
    const srcymbRef = ref(''); // 输入账号
    const srcyVfyRef = ref(); // 安全验证
    const srcyPwdRef = ref(); // 重置密码

    const errMbMsg = ''; // 输入账号错误信息
    const router = useRouter();
    const storeDatas = inject('storeDatas');
    const setFilterOfContainer = inject('setFilterOfContainer');
    // 所有数据集合
    const recoverFormDatas = reactive({
      mobile: '', // 要找回的手机号
      verifyCode: '', // 手机验证码
      passWord: '', // 更新的密码
    });

    const srcyVfyRules = reactive({
      verifyCode: [Rules.MobileVerifyRules],
    });
    const srcyPwdRules = reactive({
      passWord: [Rules.PassWordRules],
    });

    const modelType = {
      // 携带参数（向后台服务器发送）
      Mb: 'RecoverMB', // 输入账号（下一步）
      Vfy: 'RecoverVfy', // 获取手机验证码
      Code: 'RecoverVfyCode', // 校验验证码是否正确（下一步）
      Pwd: 'RecoverRestPwd', // 重置密码（确定）
    };
    const nodeRefs = {
      // 对应携带参数的Ref
      RecoverMB: srcymbRef,
      RecoverVfy: srcyVfyRef, // 与下一个相同
      RecoverVfyCode: srcyVfyRef,
      RecoverRestPwd: srcyPwdRef,
    };
    const rectvDatas = reactive({
      // 响应数据
      showForm: modelType.Mb, // 当前展示表单内容
      setInterval: null, // 保存倒计时
      savePrevMobile: { mobile: '', errmsg: '' }, // 保存上次查询过的错误电话号码
      savePrevVerifyCode: { verifyCode: '', errmsg: '' }, // 保存上次查询过的验证码
      errRecoverMB: '', // 手机号-错误提示信息
      errRecoverVfyCode: '', // 验证码-错误提示信息
      errRecoverRestPwd: '', // 密码-错误提示信息
      interVal: '获取验证码', // 记录获取验证码倒计时
    });

    // 单独验证信息
    const srcyMbRules = reactive({
      mobile: [
        {
          required: true,
          asyncValidator: (rule, value) => new Promise((resolve, reject) => {
            if (value === '') {
              reject(new Error('必填内容'));
            } else if (value.length !== 11) {
              reject(new Error('请正确填写中国大陆地区手机号'));
            } else if (!/^[1][3458]+[0-9]{9}$/.test(value)) {
              reject(new Error('请正确填写中国大陆地区手机号'));
            } else if (recoverFormDatas.mobile !== '' && recoverFormDatas.mobile === rectvDatas.savePrevMobile.mobile) {
              reject(new Error(rectvDatas.savePrevMobile.errmsg.message));
            } else {
              resolve(value);
            }
          }),
          trigger: ['blur', 'change'],
        },
      ],
    });

    // 方法函数集合
    const myMethods = reactive({
      // 输入账号提交
      formSubmit: (modelTypeName) => {
        // 先清空报错数据
        rectvDatas.errRecoverVfyCode = '';
        rectvDatas.errRecoverRestPwd = '';

        const mb = new UsersecurityClass(modelTypeName, nodeRefs[modelTypeName].value, recoverFormDatas, rectvDatas);
        const menuListClass = new MenuListClass(storeDatas, setFilterOfContainer, router);

        mb.verifyFormDatas()
          .then((isRightful) => {
            if (isRightful) {
              // 验证通过
              mb.checkMobileIsExists(menuListClass);
            } else {
              // 验证失败
              mb.focusToErrInput(0);
            }
          })
          .catch((err) => {
            console.error(err);
          });
      },
    });

    return {
      srcymbRef,
      srcyVfyRef,
      srcyPwdRef,
      nodeRefs,

      rectvDatas,
      recoverFormDatas,

      srcyMbRules,
      srcyVfyRules,

      errMbMsg,
      srcyPwdRules,
      modelType,
      ...toRefs(myMethods),
    };
  },
};
</script>
<style  lang="scss" scoped>
$titleH: 130px;
.usersecurity {
	position: relative;
	.mycardbox {
		$h: 100vh;
		position: relative;
		margin-bottom: 1px;
		height: $h;

		.mobileForm,
		.verifyForm,
		.pwdForm {
			height: 100%;

			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			.mycard {
				&:first-child {
					border-bottom-left-radius: 0;
					border-bottom-right-radius: 0;
					h1 {
						color: $submenucolor;
						position: relative;
						font-size: 28px;
						width: 100%;
						height: $titleH;
						text-align: center;
						line-height: $titleH;
						font-weight: 400;
						border-bottom: 1px solid $light;
						// font-family: '黑体';
					}
				}
				&:last-child {
					flex: 1;
					border-top-left-radius: 0;
					border-top-right-radius: 0;

					display: flex;
					justify-content: center;
				}
			}
		}
	}
	.verificationCode {
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		margin: 0;
		padding: 0;

		:deep(.el-input) {
			width: 68%;
			border-bottom-right-radius: 0;
			border-top-right-radius: 0;
		}
		:deep(.el-input .el-input__inner) {
			border-bottom-right-radius: 0;
			border-top-right-radius: 0;
		}
		.vrfCode {
			//验证码
			flex: 1;
			color: rgba($loginbtncolor, 0.8);
			text-align: center;
			vertical-align: middle;
			position: relative;
			height: 41px;
			.vrfCodeCtn {
				position: absolute;
				bottom: 0;
				width: 100%;
				box-sizing: border-box;
				-moz-box-sizing: border-box;
				cursor: pointer;
				line-height: 40px;
				height: 40px;
				border-top-right-radius: 4px;
				border-bottom-right-radius: 4px;
				border: 1px solid rgba(153, 153, 153, 0.6);
				border-left: none;
				:deep(.el-image) {
					width: 113px !important;
					height: 38px !important;
					:hover {
						color: $dark;
					}
				}
			}
		}
	}
	.contentBox {
		p {
			margin-bottom: 10px;
			color: $buttomcolor;
			font-size: 14px;
		}
		p:nth-child(2) {
			color: $dark;
		}
		margin-top: 80px;
		min-width: 430px;
		width: 40%;

		.srcymb {
			:deep(.el-input--prefix .el-input__inner) {
				padding-left: 40px;
				font-size: 16px;
				color: $dark;
			}
			:deep(.el-input__prefix) {
				font-size: 14px;
				color: $submenucolor;
			}

			:deep(.el-form-item__content) {
				width: 100%;
			}
			:deep(.el-button--primary) {
				background: $logobgcolor;
				margin-top: 20px;
				width: 100%;
				color: $white;
				border-radius: 2px;
			}
		}
	}
}
</style>
