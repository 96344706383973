<template>
  <div class="mycard">
    <slot name="mycard"></slot>
  </div>
</template>
<script>
export default {
  setup() {
  },
};
</script>
<style lang="scss" >
.mycard {
	background-color: $mycard;
	border-radius: $radiusSize;
	box-shadow: 1px 1px 3px $hovefontcolor;
}

</style>
